import './slider.css'


export const SliderComponnent = () => {

  return (
    <>
      <section class="rev_slider_wrapper" >
        <div id="rev_slider_3" class="rev_slider" data-version="5.0">
          <ul>
            <li data-transition="fade">
              <img src="images/banner/b3.png" alt="" data-bgposition="center center" data-bgfit="cover" class="rev-slidebg" />
              <div class="tp-caption  tp-resizeme"
                data-x="left" data-hoffset="20"
                data-y="top" data-voffset="300"
                data-transform_idle="o:1;"
                data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:1000;e:Power3.easeOut;"
                data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                data-start="700">

                <div class="slide-content left-slide">
                  <div class="title">Construction et batiment</div>
                  <div class="big-title">Conseils, accopagnement et réalisation <br /> pour votre projet immobilier</div>
                  <p>Notre expérience au service des entreprises<br />pour trouver des solutions globales et adaptées.</p>
                  <div class="btns-box">
                    <a href="/about" class="btn_brd">Lire plus</a>
                    <a href="/contact" class="btn_fill">Contactez nous</a>
                  </div>
                </div>
              </div>
            </li>
            <li data-transition="slideremoveright">
              <img src="images/new/study11.jpg" alt="" data-bgposition="center center" data-bgfit="cover" class="rev-slidebg" />
              <div class="tp-caption  tp-resizeme"
                data-x="right" data-hoffset="20"
                data-y="top" data-voffset="300"
                data-transform_idle="o:1;"
                data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:1000;e:Power3.easeOut;"
                data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                data-start="1500">

                <div class="slide-content right-slide">
                  <div class="title">Complétez votre rêve</div>
                  <div class="big-title">Architecutre 2D / 3D</div>
                  <p>Nous construisons les plans <br /> qui repondent à vos besoins.</p>
                  <div class="btns-box">
                    <a href="/about" class="btn_brd">Lire plus</a>
                    <a href="/contact" class="btn_fill">Contactez nous</a>
                  </div>
                </div>
              </div>
            </li>
            <li data-transition="slideremoveleftt">
              <img src="images/banner/b11.png" alt="" data-bgposition="center center" data-bgfit="cover" class="rev-slidebg" />
              <div class="tp-caption  tp-resizeme"
                data-x="left" data-hoffset="20"
                data-y="top" data-voffset="300"
                data-transform_idle="o:1;"
                data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:1000;e:Power3.easeOut;"
                data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                data-start="1500">

                <div class="slide-content left-slide">
                  <div class="title">Votre projet immobilier, notre priorité</div>
                  <div class="big-title">Prenez rendez-vous avec un expert</div>
                  <p>L'immobilier, notre métier, votre réussite, notre passion<br /> </p>
                  <div class="btns-box">
                    <a href="/about" class="btn_brd">Lire plus</a>
                    <a href="/contact" class="btn_fill">Contactez nous</a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <div id="home_icon" className="section-b">
        <div class="container">
          <div class="row">
            <div class="col-md-4 text-center">
              <a href="listing_1.html" class="home_feature">
                <i class="icon-stack2"></i>
                <h4>BATIMENTS</h4>
              </a>
            </div>
            <div class="col-md-4 text-center">
              <a href="my_properties.html" class="home_feature">
                <i class="icon-select-an-objecto-tool"></i>
                <h4>TRAVAUX PUBLIC</h4>
              </a>
            </div>
            <div class="col-md-4 text-center">
              <a href="gallery_1.html" class="home_feature">
                <i class="icon-bulb"></i>
                <h4>ARCHITECTURE 2D/3D</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}