import { a } from 'react-router-dom'
import './header.css'


export const HeaderComponent = () => {

    return (<>
        <header id="main_header" class="header_sev">

            <div id="header-top" >
                <div class="container">
                    <div class="row">
                        <div class="col-md-2 hidden-xs hidden-sm logo-top_sev">
                            <a href="/" className=''>
                                <img src="images/logo/logo.png" className='logo' alt="logo" />
                            </a>
                        </div>

                        <div class="col-md-10 col-sm-10 col-xs-12 text-right">
                            <div class="header-top-links">
                                <ul>
                                    <li><a ><i class="fa fa-facebook"></i>Facebook</a></li>
                                    <li class="af-line"></li>
                                    <li><a ><i class="fa fa-instagram"></i>Instagram</a></li>
                                    <li class="af-line"></li>
                                    <li><a ><i class="fa fa-twitter"></i>Twitter</a></li>
                                    <li class="af-line"></li>
                                    <li><a href="#" > <i className="fa fa-phone"></i> <em>(+225) 07 79 52 58 33 / 07 67 96 50 65</em></a></li>
                                    <li class="af-line"></li>
                                    <li><a href="#" > <i className="fa fa-home"></i> <em>Abidjan, Bingerville</em></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="navbar navbar-default navbar-sticky bootsnav">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                    <i class="fa fa-bars"></i>
                                </button>

                                <a class="navbar-brand sticky_logo" href="index-2.html">
                                    <img src="images/logo/logo.png" className='logo1' alt="logo" />
                                </a>
                            </div>
                            <div class="collapse navbar-collapse" id="navbar-menu">
                                <ul class="nav navbar-nav" data-in="fadeInDown" data-out="fadeOutUp">

                                    <li class="dropdown active">
                                        <a href="/" >Accueil</a>
                                    </li>

                                    <li class="dropdown">
                                        <a href="/about" >A propos</a>
                                    </li>
                                    <li className="dropdown1">
                                        <a href='#' class="dropbtn1">Activités <i className="fa fa-chevron-down"></i> </a>
                                        <div class="dropdown-content1">
                                            <h5 className='text-primary' style={{paddingLeft:10}}>Construction <i className="fa fa-chevron-down"></i></h5>
                                            <a href="#">Bâtiments</a>
                                            <a href="#">Infrastructures</a>
                                            <a href="#">Environnement</a>
                                            <h5 className='text-primary' style={{paddingLeft:10}}>Développement immobilier <i className="fa fa-chevron-down"></i></h5>
                                        </div>
                                    </li>
                                    <li class="dropdown">
                                        <a href='/product'>Produits</a>
                                    </li>

                                    <li class="dropdown">
                                        <a href='/service'>Services</a>
                                    </li>
                                    <li class="dropdown">
                                        <a href='/team' >Equipe</a>
                                    </li>
                                    <li class="dropdown">
                                        <a href='/carier' >Recrutement</a>
                                    </li>
                                    <li class="dropdown">
                                        <a href='/contact'>Contact</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </>)
}