import { MainContainerPage } from "../MainContainerPage"


export const ProductPage =() => {

    return(
        <>
        <MainContainerPage active='product' children={<>
        <section className="padding">
        <div className="container">
          <div className="row bottom40">
            <div className="col-xs-12">
              <h2 className="uppercase">
                LISTE DES <span className="color_red">PRODUITS</span>
              </h2>
              <div className="line_1" />
              <div className="line_2" />
              <div className="line_3" />
              <p className="heading_space">
                We have Properties in these Areas View a list of Featured Properties.
              </p>
            </div>
          </div>
          <div className="row bottom30">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="image">
                  <img
                    src="images/property-listing-1.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                  <div className="feature">
                    <span className="tag">Featured</span>
                  </div>
                  <div className="price">
                    <span className="tag">For Sale</span>
                  </div>
                  <div className="property_meta">
                    <span>
                      <i className="fa fa-object-group" />
                      530 sq ft{" "}
                    </span>
                    <span>
                      <i className="fa fa-bed" />2
                    </span>
                    <span>
                      <i className="fa fa-bath" />1 Bathroom
                    </span>
                  </div>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <h3>
                      <a href="property_details_1.html">House in New York City</a>
                    </h3>
                    <span className="bottom10">Merrick Way, Miami, USA</span>
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="image">
                  <img
                    src="images/property-listing-2.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="feature">
                    <span className="tag">Featured</span>
                  </div>
                  <div className="price">
                    <span className="tag">For Sale</span>
                  </div>
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                  <div className="property_meta">
                    <span>
                      <i className="fa fa-object-group" />
                      530 sq ft{" "}
                    </span>
                    <span>
                      <i className="fa fa-bed" />2
                    </span>
                    <span>
                      <i className="fa fa-bath" />1 Bathroom
                    </span>
                  </div>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <h3>
                      <a href="property_details_1.html">House in New York City</a>
                    </h3>
                    <span className="bottom10">Merrick Way, Miami, USA</span>
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="image">
                  <img
                    src="images/property-listing-3.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="feature">
                    <span className="tag">Featured</span>
                  </div>
                  <div className="price">
                    <span className="tag">For Rent</span>
                  </div>
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                  <div className="property_meta">
                    <span>
                      <i className="fa fa-object-group" />
                      530 sq ft{" "}
                    </span>
                    <span>
                      <i className="fa fa-bed" />2
                    </span>
                    <span>
                      <i className="fa fa-bath" />1 Bathroom
                    </span>
                  </div>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <h3>
                      <a href="property_details_1.html">House in New York City</a>
                    </h3>
                    <span className="bottom10">Merrick Way, Miami, USA</span>
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="property_head default_clr text-center">
                  <a href="#.">
                    <h3 className="captlize p-white ">The Helux villa</h3>
                  </a>
                  <img src="images/listing.png" alt="listin" className="start_tag" />
                  <p className="p-font-15 p-white ">45 Regent Street, London, UK</p>
                </div>
                <div className="image">
                  <img
                    src="images/property-listing-4.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="price">
                    <span className="tag">For Sale</span>
                  </div>
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
                <div className="property_meta">
                  <span>
                    <i className="fa fa-object-group" />
                    530 sq ft{" "}
                  </span>
                  <span>
                    <i className="fa fa-bed" />2
                  </span>
                  <span>
                    <i className="fa fa-bath" />1 Bathroom
                  </span>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="property_head text-center">
                  <a href="#.">
                    <h3 className="captlize">The Helux villa</h3>
                  </a>
                  <p className="p-font-15">45 Regent Street, London, UK</p>
                </div>
                <div className="image">
                  <img
                    src="images/property-listing-5.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
                <div className="property_meta">
                  <span>
                    <i className="fa fa-object-group" />
                    530 sq ft{" "}
                  </span>
                  <span>
                    <i className="fa fa-bed" />2
                  </span>
                  <span>
                    <i className="fa fa-bath" />1 Bathroom
                  </span>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="property_item heading_space">
                <div className="property_head text-center">
                  <a href="#.">
                    <h3 className="captlize">The Helux villa</h3>
                  </a>
                  <p className="p-font-15">45 Regent Street, London, UK</p>
                </div>
                <div className="image">
                  <img
                    src="images/property-listing-1.jpg"
                    alt="listin"
                    className="img-responsive"
                  />
                  <div className="overlay">
                    <div className="centered">
                      <a
                        className="link_arrow white_border"
                        href="property_details_1.html"
                      >
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
                <div className="property_meta">
                  <span>
                    <i className="fa fa-object-group" />
                    530 sq ft{" "}
                  </span>
                  <span>
                    <i className="fa fa-bed" />2
                  </span>
                  <span>
                    <i className="fa fa-bath" />1 Bathroom
                  </span>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <p>
                      <strong>$83,600,200</strong>
                    </p>
                  </div>
                  <div className="favroute clearfix">
                    <p className="pull-left">
                      <i className="icon-calendar2" /> 3 Days ago
                    </p>
                    <ul className="pull-right">
                      <li>
                        <a href="#.">
                          <i className="icon-video" />
                        </a>
                      </li>
                      <li>
                        <a href="#.">
                          <i className="icon-like" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="project1 clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12 padding-left-0 project-images">
                  <div className="gri">
                    <figure className="effect-layla">
                      <img src="images/b-d-property.jpg" alt="img" />
                      <figcaption> </figcaption>
                    </figure>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-12 project-owl-slidee padding-left-0 project-owl-slideee">
                    <div className="item background-color-white">
                      <h4>Residential Project-d05</h4>
                      <div className="small-title">
                        <div className="line1" />
                        <div className="line2" />
                        <div className="clearfix" />
                      </div>
                      <div className="client-loc">
                        <p>
                          <span>Client:</span> Bryan Doe Joe
                        </p>
                        <p>
                          <span>Location:</span> Mountain Line CA 62548
                        </p>
                        <p>
                          <span>Value:</span> $15,000
                        </p>
                      </div>
                      <a href="#." className="link_arrow">
                        read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="project1 clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12 padding-left-0 project-images">
                  <div className="gri">
                    <figure className="effect-layla">
                      <img src="images/b-d-property-2.jpg" alt="img" />
                      <figcaption> </figcaption>
                    </figure>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-12 project-owl-slidee padding-left-0 project-owl-slideee">
                    <div className="item background-color-white">
                      <h4>Residential Project-d05</h4>
                      <div className="small-title">
                        <div className="line1" />
                        <div className="line2" />
                        <div className="clearfix" />
                      </div>
                      <div className="client-loc">
                        <p>
                          <span>Client:</span> Bryan Doe Joe
                        </p>
                        <p>
                          <span>Location:</span> Mountain Line CA 62548
                        </p>
                        <p>
                          <span>Value:</span> $15,000
                        </p>
                      </div>
                      <a href="#." className="link_arrow">
                        read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row top40">
            <div className="col-md-12">
              <ul className="pager">
                <li>
                  <a href="#.">1</a>
                </li>
                <li className="active">
                  <a href="#.">2</a>
                </li>
                <li>
                  <a href="#.">3</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
        </>}>

        </MainContainerPage>
      
        </>
    )
}