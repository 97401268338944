import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { TeamComponent } from "../../componnents/team/TeamComponent"
import { MainContainerPage } from "../MainContainerPage"
import './team.css'


export const TeamPage = () => {

    return (
        <>
            <MainContainerPage active={"team"} children={
                <>
                    <div className="page-title page-main-section parallaxie ">
                        <div className="container padding-bottom-top-120 text-uppercase text-center">
                            <div className="main-title">
                                <h1>Notre equipe</h1>
                                <h5>Notre expertise, notre atout</h5>
                                <div className="line_4"></div>
                                <div className="line_5"></div>
                                <div className="line_6"></div>
                                <a href="index-2.html">Accueil</a><span><i className="fa fa-angle-double-right" aria-hidden="true"></i></span><a href="about.html">A propos</a>
                            </div>
                        </div>
                    </div>
                    <TeamComponent />
                    <CallActionComponent/>
                    <section className="info_section parallaxie">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 col-sm-4"> </div>
                                <div className="col-md-4 col-sm-4 right_box">
                                    <div className="right_box_inner padding clearfix">
                                        <div className="col-md-12 col-sm-12 white_content text-center top20 bottom30">
                                            <i className="icon-history"></i>
                                            <h3 className="bottom10 top20">5 / 7 jrs</h3>
                                            <p>Nous sommes ouvert du Lundi au vendredi de 8h-30 à 17h30</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4"> </div>
                            </div>
                        </div>
                    </section>


                </>
            } />
        </>
    )
}