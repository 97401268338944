


export const TeamComponent = () => {

    return(
        <>
        
<section id="team-sev" class="padding bg_light">
    <div class="container">

      <div class="row mb-20">
        <div class="col-sm-1 col-md-2"></div>
        <div class="col-xs-12 col-sm-10 col-md-8 text-center">
          <h2 class="text-uppercase">Notre équipe <span class="color_red">de professionnels</span></h2>
          <div class="line_1-1"></div>
          <div class="line_2-2"></div>
          <div class="line_3-3"></div>
          <p class="heading_space">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div class="col-sm-1 col-md-2"></div>
      </div>
        
      <div class="row mt-30">
          
          <div class="col-md-3 col-sm-3 col-xs-12 text-center">
              <div class="team-box">
                <img src="images/team-1.png" alt="img"/>
                  <h2>Antonios Garnelis</h2>
                  <p>VP Product</p>
                  <ul>
                    <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                  </ul>
                  
                  <div class="team-box-overlay">
                    <h2>Anita Kutlesa</h2>
                      <p>Anita is an Arizona native and has experience with rental real estate investments. She enjoys kombucha & classic video games and tennis.</p>
                  </div>
                  
              </div>
          </div>
          
          <div class="col-md-3 col-sm-3 col-xs-12 text-center">
              <div class="team-box">
                <img src="images/team-2.png" alt="img"/>
                  <h2>Amy Hawman</h2>
                  <p>VP Product</p>
                  <ul>
                    <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                  </ul>
                  
                  <div class="team-box-overlay">
                    <h2>Anita Kutlesa</h2>
                      <p>Anita is an Arizona native and has experience with rental real estate investments. She enjoys kombucha & classic video games and tennis.</p>
                  </div>
                  
              </div>
          </div>
          
          <div class="col-md-3 col-sm-3 col-xs-12 text-center">
              <div class="team-box">
                <img src="images/team-3.png" alt="img"/>
                  <h2>Anita Kutlesa</h2>
                  <p>VP Product</p>
                  <ul>
                    <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                  </ul>
                  
                  <div class="team-box-overlay">
                    <h2>Anita Kutlesa</h2>
                      <p>Anita is an Arizona native and has experience with rental real estate investments. She enjoys kombucha & classic video games and tennis.</p>
                  </div>
                  
              </div>
          </div>
          
          <div class="col-md-3 col-sm-3 col-xs-12 text-center">
              <div class="team-box">
                <img src="images/team-4.png" alt="img"/>
                  <h2>Antonios Garnelis</h2>
                  <p>VP Product</p>
                  <ul>
                    <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li>|</li>
                      <li><a href="#"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                  </ul>
                  
                  <div class="team-box-overlay">
                    <h2>Anita Kutlesa</h2>
                      <p>Anita is an Arizona native and has experience with rental real estate investments. She enjoys kombucha & classic video games and tennis.</p>
                  </div>
                  
              </div>
          </div>

      </div>
            
    </div>
</section>
        </>
    )
}