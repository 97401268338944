import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { ServiceComponent } from "../../componnents/service/ServiceComponont"
import { TeamComponent } from "../../componnents/team/TeamComponent"
import { MainContainerPage } from "../MainContainerPage"
import './about.css'


export const AboutPage = () => {

    return (
        <>
            <MainContainerPage active={"about"} children={
                <>
                    <div className="page-title page-main-section parallaxie ">
                        <div className="container padding-bottom-top-120 text-uppercase text-center">
                            <div className="main-title">
                                <h1>A propos</h1>
                                <h5>Notre expertise, notre atout</h5>
                                <div className="line_4"></div>
                                <div className="line_5"></div>
                                <div className="line_6"></div>
                                <a href="index-2.html">Accueil</a><span><i className="fa fa-angle-double-right" aria-hidden="true"></i></span><a href="about.html">A propos</a>
                            </div>
                        </div>
                    </div>

                    <section id="about_us" className="about-us padding">
                        <div className="container">
                            <div className="row">
                                <div className="history-section">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <h2 className="text-uppercase"><span className="color_red">Présentation</span> de l'entreprise</h2>
                                        <div className="line_1"></div>
                                        <div className="line_2"></div>
                                        <div className="line_3"></div>
                                        <p className="top20 bottom40">
                                            MYG-CORPORATION est une entreprise spécialisée dans la construction, la rénovation, la réparation, la gestion ou l'entretien de biens immobiliers, tels que des bâtiments, des infrastructures et d'autres structures liées à l'immobilier. <br /> <br />
                                            MYG-CORPORATION fournit une gamme de services pour aider à créer, maintenir ou améliorer des biens immobiliers.
                                        </p>
                                        {/* <a className="link_arrow dark_border top40" href="about.html">Read More</a> */}
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div id="about_single" className="owl-carousel ">
                                            <div className="item">
                                                <div className="content-right-md">
                                                    <figure className="effect-layla">
                                                        <img src="images/new/road.jpg" alt="img" className="h-carou" />
                                                        <figcaption> </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="content-right-md">
                                                    <figure className="effect-layla">
                                                        <img src="images/new/pass1.jpg" alt="img" className="h-carou" />
                                                        <figcaption> </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="content-right-md">
                                                    <figure className="effect-layla">
                                                        <img src="images/new/arch3D.jpg" alt="img" className="h-carou" />
                                                        <figcaption> </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ServiceComponent />
                    <CallActionComponent/>

                    <TeamComponent />
                    <section className="info_section parallaxie">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 col-sm-4"> </div>
                                <div className="col-md-4 col-sm-4 right_box">
                                    <div className="right_box_inner padding clearfix">
                                        <div className="col-md-12 col-sm-12 white_content text-center top20 bottom30">
                                            <i className="icon-history"></i>
                                            <h3 className="bottom10 top20">5 / 7 jrs</h3>
                                            <p>Nous sommes ouvert du Lundi au vendredi de 8h-30 à 17h30</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4"> </div>
                            </div>
                        </div>
                    </section>

                </>
            } />
        </>
    )
}