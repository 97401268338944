import { FooterComponent } from "../../componnents/layout/footer/FooterComponent"
import { HeaderComponent } from "../../componnents/layout/header/HeaderComponent"
import { MainContainerPage } from "../MainContainerPage"

export const E404 = () => {

    return (<>
        <MainContainerPage children={
            <>
                <section id="error-404">
                    <div class="container">
                        <div class="row text-center">
                            <div class="error-image col-sm-12">
                                <img src="images/404.png" alt="image" class="bottom30" />
                            </div>
                            <div class="error-text col-sm-12">
                                <h3>Oups! Page en developpement.</h3>
                                <p class="bottom20 top10">La page que vous recherchez a est actuelement en developpement, revenez plutart</p>
                                <a href="/" class="btn_fill">Aller à la page d'accueil</a>
                            </div>
                        </div>
                    </div>

                </section>


            </>
        } />
    </>)
}