import './about.css'

export const AboutComponent = () => {

  return (
    <>
      <section id="about-sev" class="padding">
        <div class="container">

          <div class="row">

            <div class="col-md-7 col-sm-7 col-xs-12">

              <div class="row">
                <div class="col-md-12 bottom40">
                  <h2 class="text-uppercase">A propos de<span class="color_red"> MYG-CORPORATION</span></h2>
                  <div class="line_1"></div>
                  <div class="line_2"></div>
                  <div class="line_3"></div>
                </div>
              </div>

              <div class="about-sev-tag">
                <div class=" about-detail">

                  <h4><em>MYG-CORPORATION est la resultante d'une association d'ingenieur spécialisé dans differents domaines liés à l'immobilier.</em></h4>
                  <p>L'entreprise est spécialisé dans :</p>
                  
                <ul>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>La construction de bâtiments</li>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>L'études et conceptions achitecturales </li>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>Les conseils et suivies procedurales </li>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>La rénovation  d'infrastructures</li>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>La réparation  d'infrastructures</li>
                  <li><i class="fa fa-check-square" aria-hidden="true"></i>L'entretien préventif des infrastructures</li>
                </ul>
                <div class="founder-text">
                  <p>Nous travaillions en étroite collaboration avec des architectes, des ingénieurs, immobiliers et d'autres acteurs de l'industrie pour réaliser des projets de construction ou de maintenance conformes aux normes et aux réglementations en vigueur.</p>
                  <h3>- M Marc Yaninck, Directeur Generale.</h3>
                </div>
                </div>



              </div>

            </div>

            <div class="col-md-5 col-sm-5 col-xs-12 about-image">
              <div class="row">
                <div class="col-md-12"> <img src="images/new/road.jpg" alt="" /> </div>
              </div>
              <div class="row">
                <div class="col-md-6"> <img src="images/new/btp2.jpg" alt="" /> </div>
                <div class="col-md-6"> <img src="images/new/arch3D.jpg" alt="" /> </div>
              </div>
                  <a href="/about" class="btn_fill mt-4">En savoir plus</a>
            </div>

          </div>

        </div>
      </section>
    </>
  )
}