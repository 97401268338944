import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";
import { HomePage } from './pages/home/HomePage';
import { AboutPage } from './pages/about/AboutPage';
import { E404 } from './pages/error/e404';
import { ServicePage } from './pages/services/ServicePage';
import { TeamPage } from './pages/team/TeamPage';
import { ContactPage } from './pages/contact/ContactPae';
import { Carier } from './pages/carrier/Carier';
import { ProductPage } from './pages/product/ProductPage';

function App() {
  return (
    <div className="">
    <Router>
      <Routes>
        <Route path='/*' element={<HomePage/>} ></Route>
        <Route path='/about' element={<AboutPage/>} ></Route>
        <Route path='/service' element={<ServicePage/>} ></Route>
        <Route path='/product' element={<ProductPage/>} ></Route>
        <Route path='/team' element={<TeamPage/>} ></Route>
        <Route path='/contact' element={<ContactPage/>} ></Route>
        <Route path='/carier' element={<Carier/>} ></Route>
        <Route path='/error-404' element={<E404/>} ></Route>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
