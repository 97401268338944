

export const CallActionComponent = () => {


    return(<>
    <section id="image-text" class="padding-bottom-top-120 parallaxie">
    <div class="container">
        <div class="row">
        <div class="col-md-12 text-center">
            <div class="image-text-heading top30 bottom30">
            <h2 class="bottom40">Nous ne nous contentons pas de trouver <br/><span>Ce qui vous convient</span> Nous le creons</h2>
            <a href="/contact" class="link_arrow white_border top10">Contactez nous</a>
            </div>
        </div>
        </div>
    </div>
    </section>
    </>)
}