
import './service.css'

export const ServiceComponent = () => {


    return (
        <>

            <section id="our-services" class="we_are bg_light padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="text-uppercase">Domaines de <span class="color_red">Compétence</span></h2>
                            <div class="line_1"></div>
                            <div class="line_2"></div>
                            <div class="line_3"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="property-list-list" data-target="Residential">
                            <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-image">
                                <img src="images/new/btp1.jpg" alt="recent-properties-1" class="img-responsive" />
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-8 property-list-list-info">
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <a href="#.">
                                        <h3>BATIMENTS</h3>
                                    </a>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <label class="property-list-list-label">Sous-domaines</label>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <p class="recent-properties-price">Constructions et batiments</p>
                                    <p>Il s'agit de la construction de nouveaux bâtiments résidentiels, commerciaux ou industriels en suivant des plans et des spécifications préétablis..</p>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 property-list-list-facility">
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Service et controle</li>
                                        <li class="right"><span>1</span> </li>
                                    </ul>
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Calcule de structure</li>
                                        <li class="right"><span>2</span> </li>
                                    </ul>
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Permis de conduire</li>
                                        <li class="right"><span>3</span> </li>
                                    </ul>
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Etude de stabilité</li>
                                        <li class="right"><span>4</span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="property-list-list" data-target="Residential">
                            <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-image">
                                <img src="images/new/road.jpg" alt="recent-properties-1" class="img-responsive"/>
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-8 property-list-list-info">
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <a href="#.">
                                        <h3>TRAVAUX PUBLIC</h3>
                                    </a>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <label class="property-list-list-label">Sous-domaine</label>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <p class="recent-properties-price">Travaux public ou TP</p>
                                    <p>C'es un ensemble d'activités et de projets de construction, de rénovation, de maintenance ou d'aménagement d'infrastructures et d'équipements publics. Ces travaux sont réalisés dans l'intérêt général d'une communauté ou d'une société.</p>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 property-list-list-facility">
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Etude de voiries et réseaux divers</li>
                                        <li class="right"><span>1</span> </li>
                                    </ul>
                                    <ul>
                                        <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Reprofilage des voies</li>
                                        <li class="right"><span>2</span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="property-list-list" data-target="Residential">
                            <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-image">
                                <img src="images/new/arch3D.jpg" alt="recent-properties-1" class="img-responsive"/>
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-8 property-list-list-info">
                                <div class="col-md-12">
                                    <a href="#.">
                                        <h3>Architecture 2D et 3D</h3>
                                    </a>
                                </div>
                                <div class="col-md-12">
                                    <p class="recent-properties-price">Architecture 2D / 3D</p>
                                    <p><b>L'architecture 2D</b>, également appelée architecture bidimensionnelle, se réfère à la représentation d'objets architecturaux en deux dimensions, généralement sur une surface plane telle qu'une feuille de papier ou un écran d'ordinateur. </p>
                                    <br/>
                                    <p><b>L'architecture 3D</b>, également connue sous le nom d'architecture tridimensionnelle, repose sur la représentation d'objets architecturaux en trois dimensions, ce qui signifie qu'elle capture la profondeur, la hauteur et la largeur d'un espace ou d'une structure. Cette approche permet de créer des modèles tridimensionnels interactifs et réalistes qui simulent l'apparence et la sensation de l'environnement architectural</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}