import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { ServiceComponent } from "../../componnents/service/ServiceComponont"
import { MainContainerPage } from "../MainContainerPage"
import './service.css'


export const ServicePage = () => {

    return (
        <>
            <MainContainerPage active={"service"} children={
                <>
                    <div className="page-title page-main-section parallaxie ">
                        <div className="container padding-bottom-top-120 text-uppercase text-center">
                            <div className="main-title">
                                <h1>Nos services</h1>
                                <h5>Notre expertise, notre atout</h5>
                                <div className="line_4"></div>
                                <div className="line_5"></div>
                                <div className="line_6"></div>
                                <a href="index-2.html">Accueil</a><span><i className="fa fa-angle-double-right" aria-hidden="true"></i></span><a href="about.html">A propos</a>
                            </div>
                        </div>
                    </div>
                    <ServiceComponent />
                    <CallActionComponent/>
                    <section id="we_are" style={{marginTop: "50px"}}>
                        <div className="container mb">
                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-xs-12">
                                    <h2 className="text-uppercase">Notre <span className="color_red">méthode de travail</span></h2>
                                    <div className="line_1"></div>
                                    <div className="line_2"></div>
                                    <div className="line_3"></div>
                                    <section id="conference-timeline">
                                        <div class="timeline-start">DEBUT</div>
                                        <div class="conference-center-line"></div>
                                        <div class="conference-timeline-content">
                                            
                                            <div class="timeline-article">
                                                <div class="content-left-container">
                                                    <div class="content-left">
                                                        <h4>AUDIT</h4>
                                                        <div class="">
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Rencontre avec le client</li>
                                                            </ul>
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Analyse eventuelle de problèmes</li>
                                                            </ul>
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Analyse des besoins du client</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="meta-date">
                                                    <span class="date2">1</span>
                                                    <span class="month">*</span>
                                                </div>
                                            </div>

                                            
                                            <div class="timeline-article">
                                                <div class="content-right-container">
                                                    <div class="content-right">
                                                        <h4>ETUDE EXPERIMENTALE</h4>
                                                        <div class="">
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Etude de stabilité</li>
                                                            </ul>
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Visite de terrain</li>
                                                            </ul>
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Calcule de structure et planning</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="meta-date">
                                                    <span class="date2">2</span>
                                                    <span class="month">*</span>
                                                </div>
                                            </div>
                                            
                                            <div class="timeline-article">
                                                <div class="content-left-container">
                                                    <div class="content-left">
                                                        <h4>REALISATION</h4>
                                                        <div class="">
                                                            <ul>
                                                                <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Traveaux de terrain</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="meta-date">
                                                    <span class="date2">3</span>
                                                    <span class="month">*</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="timeline-end">FIN</div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>


                </>
            } />
        </>
    )
}