

export const ModalContactComponent = () => {

    return(
        <>
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog modal-dialog-lg " role="document">
            <div class="modal-content">
              
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h2 class="modal-title" id="myModalLabel">How can <span class="color_red">we help?</span></h2>
              </div>
        
              <div class="modal-body">
        
                <p class="bottom40">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        
                <div class="short-msg-tab"> 
                  <ul class="nav nav-tabs" role="tablist">
                    <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Suggestion</a></li>
                    <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab"><i class="fa fa-question-circle-o" aria-hidden="true"></i> Question</a></li>
                    <li role="presentation"><a href="#messages" aria-controls="messages" role="tab" data-toggle="tab"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Problems</a></li>
                    <li role="presentation"><a href="#settings" aria-controls="settings" role="tab" data-toggle="tab"><i class="fa fa-comments-o" aria-hidden="true"></i> Feedback</a></li>
                  </ul>
                  <div class="tab-content">
                    <div role="tabpanel" class="tab-pane active" id="home">
                      <div class="row">
                        <div class="col-md-12"><h3>Suggestion</h3></div>
                        <form class="callus padding-bottom" id="contact-form">
                        
                          <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="Name" name="name" id="name" type="text"/>
                                </div>
                          </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="E - mail" name="email" id="email" type="email"/>
                                </div>
                           </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <textarea name="message" placeholder="Message" id="message"></textarea>
                                </div>
                           </div>
                        </form>
                      </div>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="profile">
                      <div class="row">
                        <div class="col-md-12"><h3>Question</h3></div>
                        <form class="callus padding-bottom" id="contact-form">
                        
                          <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="Name" name="name" id="name" type="text"/>
                                </div>
                          </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="E - mail" name="email" id="email" type="email"/>
                                </div>
                           </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <textarea name="message" placeholder="Message" id="message"></textarea>
                                </div>
                           </div>
                        </form>
                      </div>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="messages">
                      <div class="row">
                        <div class="col-md-12"><h3>Problems</h3></div>
                        <form class="callus padding-bottom" id="contact-form">
                        
                          <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="Name" name="name" id="name" type="text"/>
                                </div>
                          </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="E - mail" name="email" id="email" type="email"/>
                                </div>
                           </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <textarea name="message" placeholder="Message" id="message"></textarea>
                                </div>
                           </div>
                        </form>
                      </div>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="settings">
                      <div class="row">
                        <div class="col-md-12"><h3>Feedback</h3></div>
                        <form class="callus padding-bottom" id="contact-form">
                        
                          <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="Name" name="name" id="name" type="text"/>
                                </div>
                          </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <input class="keyword-input" placeholder="E - mail" name="email" id="email" type="email"/>
                                </div>
                           </div>
        
                           <div class="col-md-12">
                                <div class="single-query">
                                    <textarea name="message" placeholder="Message" id="message"></textarea>
                                </div>
                           </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="dark_border" data-dismiss="modal">Cancel Message</button>
                <button type="button" class="btn_fill">Send Message</button>
              </div>
        
            </div>
          </div>
        </div>
        </>
    )
}