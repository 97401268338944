

export const ImageContentComponent = () => {

    return(
        <>
        <section class="info_section parallaxie">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-sm-4"> </div>
              <div class="col-md-6 col-sm-8 right_box">
                <div class="right_box_inner padding clearfix">
                  <div class="col-md-6 col-sm-6 white_content top20 bottom30">
                    <i class="icon-library"></i>
                    <h3 class="bottom10 top20">Residential</h3>
                    <p>Duis autem vel eum iriure dolor in hend rerit in vulputate velit esse molestie vel illum dolore nulla facilisis.</p>
                  </div>
                  <div class="col-md-6 col-sm-6 white_content top20 bottom30">
                    <i class="icon-office"></i>
                    <h3 class="bottom10 top20">Commercial</h3>
                    <p>Duis autem vel eum iriure dolor in hend rerit in vulputate velit esse molestie vel illum dolore nulla facilisis.</p>
                  </div>
                  <div class="col-md-6 col-sm-6 white_content top20 bottom30">
                    <i class=" icon-user-tie"></i>
                    <h3 class="bottom10 top20">Our Best Staff</h3>
                    <p>Duis autem vel eum iriure dolor in hend rerit in vulputate velit esse molestie vel illum dolore nulla facilisis.</p>
                  </div>
                  <div class="col-md-6 col-sm-6 white_content top20 bottom30">
                    <i class="icon-history"></i>
                    <h3 class="bottom10 top20">24 Hours Services</h3>
                    <p>Duis autem vel eum iriure dolor in hend rerit in vulputate velit esse molestie vel illum dolore nulla facilisis.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}