
import { AboutComponent } from "../../componnents/about/AboutComponent"
import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { ContactComponent } from "../../componnents/contact/ContactComponent"
import { ImageContentComponent } from "../../componnents/imageContent/ImageContentComponent"
import { FooterComponent } from "../../componnents/layout/footer/FooterComponent"
import { HeaderComponent } from "../../componnents/layout/header/HeaderComponent"
import { ModalContactComponent } from "../../componnents/modal/ModalComponent"
import { PartenerCompoent } from "../../componnents/partner/PartenerComponent"
import { ServiceComponen1 } from "../../componnents/service/ServiceComponont1"
import { SliderComponnent } from "../../componnents/slider/SliderComponent"
import { TeamComponent } from "../../componnents/team/TeamComponent"
import { MainContainerPage } from "../MainContainerPage"


export const HomePage = () => {

    return(<>
        <HeaderComponent />
            <SliderComponnent/>
            <AboutComponent/>
            <CallActionComponent/>
            {/* <ServiceComponen1/> */}
            {/* <PropertiesComponent/> */}
            <TeamComponent/>
            <ImageContentComponent/>
            {/* <PartenerCompoent/> */}
            <ContactComponent/>
            <ModalContactComponent/>
        <FooterComponent/>
    </>)
}