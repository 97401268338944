import { FooterComponent } from "../componnents/layout/footer/FooterComponent"
import { HeaderComponent } from "../componnents/layout/header/HeaderComponent"
import { HeaderOtherComponent } from "../componnents/layout/header/HeaderOtherComponent"

export const MainContainerPage = ({ children, active }) => {

    return (<>
        <HeaderOtherComponent active={active} />
        {children}
        <FooterComponent/>
        
    </>)
}