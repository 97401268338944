import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { TeamComponent } from "../../componnents/team/TeamComponent"
import { MainContainerPage } from "../MainContainerPage"
import './contact.css'


export const ContactPage = () => {

    return (
        <>
            <MainContainerPage active={"contact"} children={
                <>
                    <div className="page-title page-main-section parallaxie ">
                        <div className="container padding-bottom-top-120 text-uppercase text-center">
                            <div className="main-title">
                                <h1>Contactez nous</h1>
                                <h5>Notre expertise, notre atout</h5>
                                <div className="line_4"></div>
                                <div className="line_5"></div>
                                <div className="line_6"></div>
                                <a href="index-2.html">Accueil</a><span><i className="fa fa-angle-double-right" aria-hidden="true"></i></span><a href="about.html">A propos</a>
                            </div>
                        </div>
                    </div>
                    <section id="contact-us">
                        <div class="container">
                            <div class="row padding">

                                <div class="col-md-8">
                                    <div class="bottom40">
                                        <h2 class="text-uppercase">Envoyer nous<span class="color_red"> un message </span></h2>
                                        <div class="line_1"></div>
                                        <div class="line_2"></div>
                                        <div class="line_3"></div>
                                    </div>
                                    <div class="agent-p-form p-t-30">

                                        <div class="row">
                                            <form action="/error-404" class="callus padding-bottom" id="contact-form" onsubmit="return false">

                                                <div class="form-group">
                                                    <div id="result">
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="single-query">
                                                        <input type="text" class="keyword-input" placeholder="Nom et prénoms" name="name" id="name" />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-query">
                                                        <input type="text" class="keyword-input" placeholder="Téléphone" name="phone" id="phone" />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-query">
                                                        <input type="email" class="keyword-input" placeholder="Email" name="email" id="email" />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-query">
                                                        <textarea name="message" placeholder="Message" id="message"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn_fill" id="btn_submit" name="btn_submit">Envoyer</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="bottom40">
                                        <h2 class="text-uppercase"><span class="color_red"> CONTACTS</span></h2>
                                        <div class="line_1"></div>
                                        <div class="line_2"></div>
                                        <div class="line_3"></div>
                                    </div>

                                    <div class="agent-p-contact p-t-30">
                                        <div class="agetn-contact-2">
                                            <p><i class="icon-telephone114"></i>(+225) 07 79 52 58 33 / 07 67 96 50 65</p>
                                            <a href="#.">
                                                <p><i class=" icon-icons142"></i> info@myg-corporation.com</p>
                                            </a>
                                            <a href="#.">
                                                <p><i class="icon-browser2"></i>www.myg-corporation.com</p>
                                            </a>
                                            <p><i class="icon-icons74"></i>Abidjan, Bingerville</p>
                                        </div>
                                        <ul class="socials">
                                            <li><a href="#."><i class="fa fa-facebook"></i></a></li>
                                            <li><a href="#."><i class="fa fa-instagram"></i></a></li>
                                            <li><a href="#."><i class="fa fa-twitter"></i></a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    <section className="info_section parallaxie">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 col-sm-4"> </div>
                                <div className="col-md-4 col-sm-4 right_box">
                                    <div className="right_box_inner padding clearfix">
                                        <div className="col-md-12 col-sm-12 white_content text-center top20 bottom30">
                                            <i className="icon-history"></i>
                                            <h3 className="bottom10 top20">5 / 7 jrs</h3>
                                            <p>Nous sommes ouvert du Lundi au vendredi de 8h-30 à 17h30</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4"> </div>
                            </div>
                        </div>
                    </section>
                        <div class="contact">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15890.302097668886!2d-3.9980495!3d5.328702150000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sci!4v1694354547472!5m2!1sfr!2sci" width="600" height="450" style={{border:0, width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </section>


                </>
            } />
        </>
    )
}