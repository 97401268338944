
import './footer.css'


export const FooterComponent = () => {

    return (
        <>
            <footer id="footer" class="footer divider layer-overlay overlay-dark-8">
                <div class="container pt-70 pb-40">
                    <div class="row border-bottom">
                        <div class="col-sm-6 col-md-6">
                            <div class="widget dark">
                                <img src="images/logo.jpg" className="logo1 mb" alt="logo" />
                                <p>Abidjan, Bingerville</p>
                                <ul class="list-inline mt-5">
                                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-phone text-theme-color-2 mr-5"></i> <a class="text-gray" href="#.">(+225) 07 79 52 58 33 / 07 67 96 50 65</a> </li>
                                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-envelope-o text-theme-color-2 mr-5"></i> <a class="text-gray" href="#.">info@myg-corporation.com</a> </li>
                                    <li class="m-0 pl-10 pr-10"> <i class="fa fa-globe text-theme-color-2 mr-5"></i> <a class="text-gray" href="#.">www.myg-corporation.com</a> </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="widget dark">
                                <h4 class="widget-title">Liens rapides</h4>
                                <div class="small-title">
                                    <div class="line1 background-color-white"></div>
                                    <div class="line2 background-color-white"></div>
                                    <div class="clearfix"></div>
                                </div>
                                <ul class="list angle-double-right list-border">
                                    <li> <a href="/">Accueil </a></li>
                                    <li> <a href="/about">A propos </a></li>
                                    <li> <a href="/service">Services</a></li>
                                    <li> <a href="/team">Equipes </a></li>
                                    <li> <a href="/contact">Contact </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                        <div class="col-md-6">
                            <div class="widget dark">
                                <h5 class="widget-title mb-10">Appelez nous maintenant</h5>
                                <div class="text-gray"> (+225) 07 79 52 58 33 / 07 67 96 50 65 <br />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="widget dark">
                                <h5 class="widget-title mb-10">Contactez nous</h5>
                                <ul class="socials">
                                    <li><a href="#."><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="#."><i class="fa fa-instagram"></i></a></li>
                                    <li><a href="#."><i class="fa fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom bg-black-333">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-sm-5">
                                <p class="font-11 text-black-777 m-0 copy-right">Copyright : 2023 <a href="#."><span class="color_red">MYG-BUILDING</span></a>. Tous droits réservés</p>
                            </div>
                            <div class="col-md-6 col-sm-7 text-right">
                                <div class="widget no-border m-0">
                                    <ul class="list-inline sm-text-center mt-5 font-12">
                                        <li> <a href="#.">Service d'assistance</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}