

export const ContactComponent = () => {

    return(
        <>
        <section id="contact" class="bg-color-red">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                <div class="get-tuch">
                  <i class="icon-telephone114"></i>
                  <ul>
                    <li> <h4>Numero de téléphone</h4> </li>
                    <li> <p>(+225) 07 79 52 58 33 / 07 67 96 50 65</p> </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                <div class="get-tuch">
                  <i class="icon-icons74"></i>
                  <ul>
                    <li><h4>Abidjan,</h4></li>
                    <li><p>Bingerville, Côte d'Ivoire</p></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                <div class="get-tuch">
                  <i class=" icon-icons142"></i>
                  <ul>
                    <li><h4>Adresse email</h4></li>
                    <li><a href="#.">info@myg-corrporation.com</a> </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}