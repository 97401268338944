

export const HeaderOtherComponent = ({ active }) => {

  return (
    <>
      <header id="main_header">
        <div id="header-top">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <p class="p-font-15 p-white">L'immobilier, notre passion.</p>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12 text-right">
                <div class="header-top-links">
                  <ul>
                    <li><a href="favorite-properties.html"><i class="fa fa-facebook"></i>Facebook</a></li>
                    <li class="af-line"></li>
                    <li><a href="submit-property.html"><i class="fa fa-instagram"></i>Instagram</a></li>
                    <li class="af-line"></li>
                    <li><a href="my-properties.html"><i class="fa fa-twitter"></i>Twitter</a></li>
                    <li class="af-line"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-2 hidden-xs hidden-sm">
                <a href="/">
                  <img src="images/logo.jpg" className='logo' alt="logo" />
                </a>
              </div>
              <div class="col-md-10 col-sm-12 col-xs-12">
                <div class="get-tuch text-left top20">
                  <i class="icon-telephone114"></i>
                  <ul>
                    <li>
                      <h4>Téléphone</h4>
                    </li>
                    <li>
                      <p>(+225) 07 79 52 58 33 / 07 67 96 50 65</p>
                    </li>
                  </ul>
                </div>
                <div class="get-tech-line top20"><img src="images/get-tuch-line.png" alt="line" /></div>
                <div class="get-tuch text-left top20">
                  <i class="icon-icons74"></i>
                  <ul>
                    <li>
                      <h4>Adresse</h4>
                    </li>
                    <li>
                      <p>Abidjan, Bingerville</p>
                    </li>
                  </ul>
                </div>
                <div class="get-tech-line top20"><img src="images/get-tuch-line.png" alt="line" /></div>
                <div class="get-tuch text-left top20">
                  <i class=" icon-icons142"></i>
                  <ul>
                    <li>
                      <h4>Email</h4>
                    </li>
                    <li>
                      <p><a href="#">info@myg-corporation.com</a></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-default navbar-sticky bootsnav">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i class="fa fa-bars"></i></button>
                  <a class="navbar-brand sticky_logo" href="index-2.html">
                    <img src="images/logo.jpg" class="logo" alt="" />
                  </a>
                </div>
                <div class="collapse navbar-collapse" id="navbar-menu">
                  <ul class="nav navbar-nav" data-in="fadeInDown" data-out="fadeOutUp">

                    <li class="dropdown">
                      <a href="/" >Accueil</a>
                    </li>

                    <li class={active == "about" ? "dropdown active" : "dropdown"}>
                      <a href="/about" >A propos</a>
                    </li>
                    <li className="dropdown1">
                      <a href='#' class="dropbtn1">Activités <i className="fa fa-chevron-down"></i> </a>
                      <div class="dropdown-content1">
                        <h5 className='text-primary' style={{ paddingLeft: 10 }}>Construction <i className="fa fa-chevron-down"></i></h5>
                                            <a href="#">Bâtiments</a>
                        <a href="#">Infrastructures</a>
                        <a href="#">Environnement</a>
                        <h5 className='text-primary' style={{ paddingLeft: 10 }}>Développement immobilier <i className="fa fa-chevron-down"></i></h5>
                      </div>
                    </li>

                    <li class={active == "product" ? "dropdown active" : "dropdown"}>
                      <a href="/product"  >Produits</a>
                    </li>

                    <li class={active == "service" ? "dropdown active" : "dropdown"}>
                      <a href="/service"  >Services</a>
                    </li>

                    <li class={active == "team" ? "dropdown active" : "dropdown"}>
                      <a href="/team" >Equipe</a>
                    </li>

                    <li class={active == "recrutement" ? "dropdown active" : "dropdown"}>
                      <a href="/carier" >Recrutements</a>
                    </li>

                    <li class={active == "contact" ? "dropdown active" : "dropdown"}>
                      <a href="/contact" >Contact</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>

      </header>
    </>
  )
}