import { CallActionComponent } from "../../componnents/callAction/CallActionComponent"
import { TeamComponent } from "../../componnents/team/TeamComponent"
import { MainContainerPage } from "../MainContainerPage"


export const Carier = () => {
    return (<>
        <MainContainerPage active={"recrutement"} children={
            <>
                <div className="page-title page-main-section parallaxie ">
                    <div className="container padding-bottom-top-120 text-uppercase text-center">
                        <div className="main-title">
                            <h1>Offres d'emploi</h1>
                            <h5>Disponible</h5>
                            <div className="line_4"></div>
                            <div className="line_5"></div>
                            <div className="line_6"></div>
                            <a href="index-2.html">Accueil</a><span><i className="fa fa-angle-double-right" aria-hidden="true"></i></span><a href="about.html">Recrutement</a>
                        </div>
                    </div>
                </div>

                <section id="our-services" class="we_are bg_light padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="text-uppercase">Offres d'emplois <span class="color_red">Disponible</span></h2>
                                <div class="line_1"></div>
                                <div class="line_2"></div>
                                <div class="line_3"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="property-list-list" data-target="Residential">
                                <div class="col-xs-12 col-sm-12 col-md-12 property-list-list-info">
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <a href="#.">
                                            <h3>ARCHITECTE</h3>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <label class="property-list-list-label">Exigences</label>
                                    </div>
                                    <div class="col-xs-12 col-sm-8 col-md-8">
                                        <h4>Description du poste :</h4>
                                        <p >Notre entreprise innovante recherche un architecte talentueux et créatif pour rejoindre notre équipe dynamique. En tant qu'architecte, vous serez responsable de concevoir et superviser la réalisation de projets architecturaux, en veillant à ce qu'ils répondent aux besoins esthétiques, fonctionnels et techniques.</p>
                                        <hr className="p-0" />
                                        <h4>Responsabilités principales :</h4>
                                        <p>1. Concevoir des plans architecturaux en tenant compte des exigences du client, des normes de construction et des réglementations en vigueur. <br />
                                            2. Collaborer étroitement avec les clients pour comprendre leurs besoins et leurs préférences.<br />
                                            3. Superviser la création de maquettes et de rendus pour visualiser les concepts architecturaux.<br />
                                            4. Coordonner avec les ingénieurs et autres professionnels pour garantir la faisabilité technique des projets.<br />
                                            5. Assurer le respect des délais et des budgets établis pour chaque projet.<br />
                                            6. Effectuer des visites sur site pour évaluer les conditions existantes et garantir la conformité avec les plans.<br />
                                            7. Restez à jour sur les tendances et les nouvelles technologies dans le domaine de l'architecture.</p>
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-facility">
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>  Diplôme en architecture avec une licence professionnelle.</li>
                                            <li class="right"><span>1</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Expérience professionnelle pertinente dans la conception architecturale.</li>
                                            <li class="right"><span>2</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>Excellentes compétences en communication et en gestion de projet.</li>
                                            <li class="right"><span>3</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Capacité démontrée à travailler en équipe et à collaborer avec divers professionnels.</li>
                                            <li class="right"><span>4</span> </li>
                                        </ul>
                                        <p >Envoyé votre CV et lettre de motivation à <a href="#" className="text-primary">test@gmccorporation.com</a> </p>
                                    </div>
                                </div>
                            </div>
                            <div class="property-list-list" data-target="Residential">
                                <div class="col-xs-12 col-sm-12 col-md-12 property-list-list-info">
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <a href="#.">
                                            <h3>ARCHITECTE</h3>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <label class="property-list-list-label">Exigences</label>
                                    </div>
                                    <div class="col-xs-12 col-sm-8 col-md-8">
                                        <h4>Description du poste :</h4>
                                        <p >Notre entreprise innovante recherche un architecte talentueux et créatif pour rejoindre notre équipe dynamique. En tant qu'architecte, vous serez responsable de concevoir et superviser la réalisation de projets architecturaux, en veillant à ce qu'ils répondent aux besoins esthétiques, fonctionnels et techniques.</p>
                                        <hr className="p-0" />
                                        <h4>Responsabilités principales :</h4>
                                        <p>
                                            1. Concevoir des plans architecturaux en tenant compte des exigences du client, des normes de construction et des réglementations en vigueur. <br />
                                            2. Collaborer étroitement avec les clients pour comprendre leurs besoins et leurs préférences.<br />
                                            3. Superviser la création de maquettes et de rendus pour visualiser les concepts architecturaux.<br />
                                            4. Coordonner avec les ingénieurs et autres professionnels pour garantir la faisabilité technique des projets.<br />
                                            5. Assurer le respect des délais et des budgets établis pour chaque projet.<br />
                                            6. Effectuer des visites sur site pour évaluer les conditions existantes et garantir la conformité avec les plans.<br />
                                            7. Restez à jour sur les tendances et les nouvelles technologies dans le domaine de l'architecture.
                                        </p>
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-facility">
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>  Diplôme en architecture avec une licence professionnelle.</li>
                                            <li class="right"><span>1</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Expérience professionnelle pertinente dans la conception architecturale.</li>
                                            <li class="right"><span>2</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>Excellentes compétences en communication et en gestion de projet.</li>
                                            <li class="right"><span>3</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Capacité démontrée à travailler en équipe et à collaborer avec divers professionnels.</li>
                                            <li class="right"><span>4</span> </li>
                                        </ul>
                                        <p >Envoyé votre CV et lettre de motivation à <a href="#" className="text-primary">test@gmccorporation.com</a> </p>
                                    </div>
                                </div>
                            </div>
                            <div class="property-list-list" data-target="Residential">
                                <div class="col-xs-12 col-sm-12 col-md-12 property-list-list-info">
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <a href="#.">
                                            <h3>ARCHITECTE</h3>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <label class="property-list-list-label">Exigences</label>
                                    </div>
                                    <div class="col-xs-12 col-sm-8 col-md-8">
                                        <h4>Description du poste :</h4>
                                        <p >Notre entreprise innovante recherche un architecte talentueux et créatif pour rejoindre notre équipe dynamique. En tant qu'architecte, vous serez responsable de concevoir et superviser la réalisation de projets architecturaux, en veillant à ce qu'ils répondent aux besoins esthétiques, fonctionnels et techniques.</p>
                                        <hr className="p-0" />
                                        <h4>Responsabilités principales :</h4>
                                        <p>1. Concevoir des plans architecturaux en tenant compte des exigences du client, des normes de construction et des réglementations en vigueur. <br />
                                            2. Collaborer étroitement avec les clients pour comprendre leurs besoins et leurs préférences.<br />
                                            3. Superviser la création de maquettes et de rendus pour visualiser les concepts architecturaux.<br />
                                            4. Coordonner avec les ingénieurs et autres professionnels pour garantir la faisabilité technique des projets.<br />
                                            5. Assurer le respect des délais et des budgets établis pour chaque projet.<br />
                                            6. Effectuer des visites sur site pour évaluer les conditions existantes et garantir la conformité avec les plans.<br />
                                            7. Restez à jour sur les tendances et les nouvelles technologies dans le domaine de l'architecture.</p>
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-4 property-list-list-facility">
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>  Diplôme en architecture avec une licence professionnelle.</li>
                                            <li class="right"><span>1</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Expérience professionnelle pertinente dans la conception architecturale.</li>
                                            <li class="right"><span>2</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i>Excellentes compétences en communication et en gestion de projet.</li>
                                            <li class="right"><span>3</span> </li>
                                        </ul>
                                        <ul>
                                            <li class="left"><i class="fa fa-circle text-success" aria-hidden="true"></i> Capacité démontrée à travailler en équipe et à collaborer avec divers professionnels.</li>
                                            <li class="right"><span>4</span> </li>
                                        </ul>
                                        <p >Envoyé votre CV et lettre de motivation à <a href="#" className="text-primary">test@gmccorporation.com</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="about_us" className="about-us padding">
                    <div className="container">
                        <div className="row">
                            <div className="history-section">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <h2 className="text-uppercase"><span className="color_red">Présentation</span> de l'entreprise</h2>
                                    <div className="line_1"></div>
                                    <div className="line_2"></div>
                                    <div className="line_3"></div>
                                    <p className="top20 bottom40">
                                        MYG-CORPORATION est une entreprise spécialisée dans la construction, la rénovation, la réparation, la gestion ou l'entretien de biens immobiliers, tels que des bâtiments, des infrastructures et d'autres structures liées à l'immobilier. <br /> <br />
                                        MYG-CORPORATION fournit une gamme de services pour aider à créer, maintenir ou améliorer des biens immobiliers.
                                    </p>
                                    {/* <a className="link_arrow dark_border top40" href="about.html">Read More</a> */}
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div id="about_single" className="owl-carousel ">
                                        <div className="item">
                                            <div className="content-right-md">
                                                <figure className="effect-layla">
                                                    <img src="images/new/road.jpg" alt="img" className="h-carou" />
                                                    <figcaption> </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="content-right-md">
                                                <figure className="effect-layla">
                                                    <img src="images/new/pass1.jpg" alt="img" className="h-carou" />
                                                    <figcaption> </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="content-right-md">
                                                <figure className="effect-layla">
                                                    <img src="images/new/arch3D.jpg" alt="img" className="h-carou" />
                                                    <figcaption> </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CallActionComponent />

                <section className="info_section parallaxie">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 col-sm-4"> </div>
                            <div className="col-md-4 col-sm-4 right_box">
                                <div className="right_box_inner padding clearfix">
                                    <div className="col-md-12 col-sm-12 white_content text-center top20 bottom30">
                                        <i className="icon-history"></i>
                                        <h3 className="bottom10 top20">5 / 7 jrs</h3>
                                        <p>Nous sommes ouvert du Lundi au vendredi de 8h-30 à 17h30</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4"> </div>
                        </div>
                    </div>
                </section>

            </>
        } />
    </>)
}